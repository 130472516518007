
const alidados = {
    AWSSKILLBUILDER: "https://point-files.s3.us-west-2.amazonaws.com/market-place/headers-aliados/awsBanner.png",
    COURSERA: "https://point-files.s3.us-west-2.amazonaws.com/market-place/headers-aliados/courseraBanner.png",
    CREHANA: "https://point-files.s3.us-west-2.amazonaws.com/market-place/headers-aliados/crehanaBanner.png",
    GOOGLESKILLSHOP: "https://point-files.s3.us-west-2.amazonaws.com/market-place/headers-aliados/googleSkillShopBanner.png",
    HUBSPOTACADEMY: "https://point-files.s3.us-west-2.amazonaws.com/market-place/headers-aliados/hubSpotAcademyBanner.png",
    IEUNIVERSITY: "https://point-files.s3.us-west-2.amazonaws.com/market-place/headers-aliados/ieUniversityBanner.png",
    LINKEDINLEARNING: "https://point-files.s3.us-west-2.amazonaws.com/market-place/headers-aliados/linkedInBanner.png",
}

const alidadosInfo = {
    "coursera" : { name: 'Coursera', id: 5},
    "linkedin-learning" : { name: 'LinkedIn Learning', id: 6},
    "google-skillshop" : { name: 'Google SkillShop', id: 12},
    "crehana" : { name: 'Crehana', id: 13},
    "hubspot-academy" : { name: 'Hubspot Academy', id: 14},
    // "ie-publishing" : { name: 'IE University', id: 15},
    "aws-skill builder" : { name: 'AWS Skill Builder', id: 19},
}


export const AlliesLogos = (name) => {
    const allyName = alidadosInfo[name].name;
    const allyId = alidadosInfo[name].id;
    
    const allyNameJoined = allyName.replace(/\s/g, '');
    const formatName = allyNameJoined.toUpperCase();
    
    return { 
        allyName, 
        allyBanner: alidados[formatName],
        allyId
    }
}

