export const DATA = {
    "/" : {
        "title" : `Talisis`,
        "description" : `Disfruta de cursos en oferta con Talisis 🎉¡Regístrate!`,
    },
    // "/busqueda?filtro=ZmlsdHJvLTE9QkE=" : {
    //     "title" : `Back to school: Cuando tu quieras con Prepa UNID | Talisis`,
    //     "description" : `Regresa a la prepa en línea con UNID en Talisis. y obtén un 20% OFF al pagar anualmente cualquier membresía. ¡Empieza tu educación con nosotros hoy mismo!`,
    // },
    "/busqueda?academia=6" : {
        "title" : `Cursos de Arte en línea | Talisis`,
        "description" : `Potencializa tu creatividad con nuestros cursos de arte en línea. Aprende arte, fotografía, Photoshop y más con maestros especialistas en sus campos. ¡Únete ya!`,
    },
    "/busqueda?academia=9" : {
        "title" : `Cursos de Ciencias en línea  | Talisis`,
        "description" : `Amplía tus conocimientos científicos con nuestros cursos de ciencias en línea. Aprende química, robótica y más de la mano de expertos. ¡Inscríbete hoy!`,
    },
    "/busqueda?academia=15" : {
        "title" : `Cursos de Ciencias Sociales en línea  | Talisis`,
        "description" : `¿Apasionado por la historia, psicología y política? En Talisis.com, nuestros cursos de ciencias sociales en línea son perfectos para ti. ¡Inscríbete ahora!`,
    },
    "/busqueda?academia=13" : {
        "title" : `Cursos de Derecho en línea  | Talisis`,
        "description" : `¿Quieres estudiar derecho? Talisis.com ofrece cursos en línea con expertos. Aprende desde cualquier lugar lo que necesitas para tu carrera. ¡Regístrate gratis!`,
    },
    "/busqueda?academia=2" : {
        "title" : `Cursos de Desarrollo Urbano en línea  | Talisis`,
        "description" : `Amplía tus conocimientos en Desarrollo Urbano con nuestros cursos en línea. Empieza tu proyecto de urbanización de la mano de expertos. ¡Únete ya!`,
    },
    "/busqueda?academia=10" : {
        "title" : `Cursos de Ciencia de Datos en línea  | Talisis`,
        "description" : `Aprende sobre data science en los cursos en línea disponibles en Talisis.com. Conoce la oferta de cursos de Python, SQL, PowerBI y mucho más. ¡Únete hoy!`,
    },
    "/busqueda?academia=8" : {
        "title" : `Cursos de Educación en línea  | Talisis`,
        "description" : `Descubre nuestro catálogo de cursos de educación en línea y amplía tu conocmiento de pedadogia de la mano de expertos. ¡Impulsa tu perfil profesional hoy!`,
    },
    "/busqueda?academia=14" : {
        "title" : `Cursos de Idiomas en línea  | Talisis`,
        "description" : `Aprende un idioma (o dos) con nuestros cursos en línea. En Talisis.com contamos desde certificaciones en inglés a cursos de francés, alemán y más. ¡Empieza hoy!`,
    },
    "/busqueda?academia=3" : {
        "title" : `Cursos de Ingeniería en línea | Talisis`,
        "description" : `¿Quieres mejorar tus habilidades de ingeniería? Talisis.com ofrece cursos innovadores en línea para ayudarte a alcanzar tus objetivos. ¡Regístrate ahora!`,
    },
    "/busqueda?academia=12" : {
        "title" : `Cursos de Liderazgo en línea  | Talisis`,
        "description" : `Conviértete en el líder que siempre has querido ser con Talisis.com. Aprende habilidades de liderazgo innovadoras en línea con expertos. ¡Regístrate ahora!`,
    },
    "/busqueda?academia=4" : {
        "title" : `Cursos de Marketing en línea  | Talisis`,
        "description" : `¿Estás listo para llevar tu carrera al siguiente nivel? Inscríbete a nuestros cursos en línea de marketing digital, redes sociales y publicidad. ¡Empieza hoy!`,
    },
    "/busqueda?academia=5" : {
        "title" : `Cursos de Negocios en línea  | Talisis`,
        "description" : `Haz que tu carrera despegue con Talisis.com. Aprende habilidades empresariales en línea con expertos: administración, mercadotecnia y finanzas. ¡Regístrate gratis!`,
    },
    "/busqueda?academia=7" : {
        "title" : `Cursos de Salud en línea  | Talisis`,
        "description" : `Aprende sobre bienestar, wellness, salud mental y más, para transformar tu vida y la de los demás con nuestros cursos de salud en línea. ¡Comienza hoy GRATIS!`,
    },
    "/busqueda?academia=11" : {
        "title" : `Cursos de sustentabilidad en línea  | Talisis`,
        "description" : `Estudia en línea los principios de la sustentabilidad y desarrolla tu proyecto para trabajar por un futuro más eco-friendly y sostenible. ¡Regístrate gratis!`,
    },
    "/busqueda?academia=1" : {
        "title" : `Cursos de Computación en línea  | Talisis`,
        "description" : `¿Quieres desarrollar tus habilidades computacionales? Conoce nuestra oferta de cursos en línea, desde programación básica a técnicas más complejas. ¡Únete hoy!`,
    },
    "/cursos-transformacion-social" : {
        "title" : `Cursos de Transformación Social en línea | Talisis +1,600 cursos`,
        "description" : `En Talisis podrás acceder a cursos ilimitados en línea de transformación social de Crehana, LinkedIn Learning y más desde $358 pesos al mes. ¡Únete hoy!`,
    },
    "/linkedinlearning" : {
        "title" : `LinkedIn Learning | Talisis 🎉 30% OFF en Membresias`,
        "description" : `30% OFF en membresías anuales y semestrales de LinkedIn Learning 🎉. Accede a miles de cursos online. ¡Suscríbete hoy y mejora tus habilidades!`,
    },
    "/coursera" : {
        "title" : `Coursera | 30% OFF en Membresias Anuales y Semestrales`,
        "description" : `30% OFF en membresías anuales y semestrales de Coursera 🎉. Accede a cursos y certificaciones de las mejores universidades. ¡Inscríbete ya!`,
    },
    "/escuelas/u-erre" : {
        "title" : `U-ERRE - MBAs y cursos en línea desde $1,199 pesos al mes`,
        "description" : `¡Obtén tu MBA en línea y avalada por la SEP desde $1,341 pesos al mes! Conoce la oferta educativa de la U-ERRE en Talisis. Aprende desde cualquier lugar.`,
    },
    "/escuelas/unid" : {
        "title" : `UNID - Licenciaturas en línea desde $674 pesos al mes`,
        "description" : `Conoce la oferta educativa de UNID Online en Talisis. Encuentra licenciaturas en línea avaladas por la SEP desde $674 pesos al mes. ¡Comienza hoy!`,
    },
    "/escuelas/harmonhall" : {
        "title" : `Harmon Hall - Cursos de inglés en línea y clases privadas`,
        "description" : `¡Aprende o mejora tu nivel de inglés con los cursos en línea de Harmon Hall en Talisis! Domina el idioma con nuestra metodología y profesores calificados.`,
    },
    "/google-skillshop" : {
        "title" : `Cursos de Google Skillshop en Talisis - GRATIS`,
        "description" : `Aprende marketing digital y publicidad con los cursos gratuitos de Google Skillshop en Talisis. Cursos en línea de Google Ads, Google Analytics, eCommerce y más.`,
    },
    "/crehana" : {
        "title" : `Cursos de Crehana en Talisis - desde $358 pesos al mes`,
        "description" : `Aprende a tu ritmo con los cursos de Crehana en Talisis. Por solo $358 pesos al mes tienes acceso ilimitado a Crehana, Linkedin Learning y más. ¡Inscríbete hoy! `,
    },
    "/hubspot-academy" : {
        "title" : `Cursos de HubSpot Academy en Talisis - GRATIS`,
        "description" : `Descubre el Inbound Marketing y Marketing Digital con los cursos en línea de HubSpot Academy. Regístrate GRATIS en Talisis y descubre +1,600 cursos en línea.`,
    },
    // "/ie-publishing" : {
    //     "title" : `Cursos de IE University en Talisis`,
    //     "description" : `Desarrolla tus habilidades con los cursos de IE University de Madrid en linea. Descubre cursos impartidos por expertos en negocios, tecnología y más. ¡Regístrate!`,
    // },
    "/nosotros" : {
        "title" : `Talisis | Te compartimos quiénes somos`,
        "description" : `Somos la plataforma de aprendizaje en línea que logra transformar comunidades a través de las personas. Impulsa tu profesión con Talisis. ¡Conoce más aquí!`,
    },
    "/vacantes" : {
        "title" : `Vacantes en Talisis | ¡Únete al equipo!`,
        "description" : `Únete al equipo de Talisis y sé el lider que impulsa la transformación. Revisa las vacantes disponibles que tenemos en Talisis.`,
    },
    "/busqueda?aliados=true" : {
        "title" : `Nuestros Aliados: Coursera, Crehana y más | Talisis`,
        "description" : `Aprende con los programa de nuestros aliados: Coursera, LinkedIn Learning, Crehana, HubSpot Academy y Google. Estudia a tu ritmo, donde y cuando quieras.`,
    },
    "/licenciaturas-online" : {
        "title" : `Licenciaturas en línea desde $899 pesos al mes | Talisis`,
        "description" : `¡Estudia en la mejor universidad en línea de México! Conoce nuestra oferta de licenciaturas, desde Administración de Empresas, Educación, Derecho y mucho más.`,
    },
    "/maestrias-online" : {
        "title" : `Maestrías en línea desde $899 pesos al mes | Talisis`,
        "description" : `¡Eleva tu perfil profesional con un MBA! Estudia tu MBA en línea a un precio accesible y compleméntalo con nuestros cursos con aliados. ¡Empieza hoy!`,
    },
    "/cursos" : {
        "title" : `Cursos y certificaciones en línea | Talisis`,
        "description" : `El entorno virtual de aprendizaje ideal para llevar tu carrera al siguiente nivel. Conoce nuestra oferta de cursos y certificaciones en línea. ¡Regístrate gratis!`,
    },
    "/becas" : {
        "title" : `Becas para estudiantes: estudia en línea con Talisis.com`,
        "description" : `Alcanza tus metas personales y profesionales con las becas disponibles en Talisis.com. Descubre una extensa oferta de cursos, licenciaturas y maestrías en línea.`,
    },
    "/promociones" : {
        "title" : `Promociones y Descuentos en educación online | Talisis`,
        "description" : `Estudiar en línea al mejor precio. Conoce los descuentos y promociones que tenemos en Talisis y desarrolla tus capacidades hoy.`,
    },
    "/faq" : {
        "title" : `¿Cuáles son los beneficios de los cursos online? Y más FAQ`,
        "description" : `Te respondemos cualquier duda... desde ¿cómo estudiar una carrera online? hasta ¿cuáles son las mejores universidades en línea? Visita nuestra página de FAQ.`,
    },
    "/membresias" : {
        "title" : `Cursos en línea: membresías y precios | Talisis`,
        "description" : `Encuentra la membresía que necesitas y comienza hoy a elevar tu perfil profesional. Ingresa a las mejores plataformas de educación en línea al mejor precio.`,
    },
    "blog.talisis.com" : {
        "title" : `Blog Talisis.com`,
        "description" : `¿Quieres impulsar tu vida a través del conocimiento? Encuentra en el blog de Talisis.com artículos que te ayudarán a lograrlo.`,
    },
    "/terminos_condiciones" : {
        "title" : `Términos y condiciones de Talisis.com`,
        "description" : `¡Antes de empezar a desarrollar tus habilidades no te olvides de leer nuestros términos y condiciones aquí!`,
    },
    "/aviso_privacidad" : {
        "title" : `Aviso de Privacidad Talisis`,
        "description" : `Consulta aquí nuestro aviso de privacidad y conoce cómo tratamos tus datos y respetamos tu privacidad en nuestra plataforma en línea.`,
    },
    "/login" : {
        "title" : `Login Talisis - Sigue creciendo y aprendiendo`,
        "description" : `¡Bienvenido de nuevo a Talisis.com! Entra a tu cuenta y explora los cursos que tenemos para que siga creciendo tu perfil profesional.`,
    },
    "/signup" : {
        "title" : `Sign Up Talisis - Comienza tu aprendizaje en linea`,
        "description" : `¡Aprende algo nuevo hoy! Regístrate aquí en Talisis.com y explora todos los cursos, licenciaturas y maestrías en línea para potencializar tu perfil profesional.`,
    },
    "/eventos" : {
        "title" : `Eventos Online Exclusivos de Talisis.com`,
        "description" : `¡Descubre nuestros eventos online exclusivos para miembros! Harmon Hall, UERRE, UNID IE Salud y Advenio te esperan. ¡Regístrate ahora y no te pierdas de nada!`,
    },
    "/conecta" : {
        "title" : `CONECTA - Bolsa de trabajo Talisis`,
        "description" : `En Talisis.com te conectamos con más de 3,000 empresas y te ayudamos a encontrar tu trabajo ideal. Descubre prácticas profesionales, servicio social, y mucho más.`,
    },
    "/about-us" : {
        "title" : `Conoce la plataforma educativa con más cursos en línea`,
        "description" : `En Talisis.com estamos transformando la educación a través de nuestra plataforma virtual con más de 3000 cursos y certificaciones en línea. ¡Únete a Talisis hoy!`,
    },
    "/test-vocacional/UERRE" : {
        "title" : `Test Vocacional GRATIS - ¿Cuál es tu vocación?`,
        "description" : `¡Decide tu futuro profesional aquí! Toma nuestro test vocacional gratis en Talisis.com y encuentra tu camino profesional. ¡Empieza hoy!`,
    },
    "/programa/720" : {
        "title" : `Maestría en Administración: Gerencia Global | U-ERRE`,
        "description" : `Obtén una maestría en línea en Administración con énfasis en Gerencia Global en Talisis.com por solo $1, 800 mxn/mes. ¡Avanza en tu carrera profesional hoy!`,
    },
    "/programa/721" : {
        "title" : `Maestría en Administración: Mercadotecnia Internacional | U-ERRE`,
        "description" : `Lleva tu carrera al siguiente nivel con la maestría en línea en Administración con enfoque en Mercadotecnia Internacional. ¡Únete por solo $1,800 mxn/mes!`,
    },
    "/programa/309" : {
        "title" : `Certificación De Green Belt en línea de U-ERRE`,
        "description" : `¿Estás buscando mejorar tus habilidades en gestión de procesos y toma de decisiones? ¡Únete hoy al programa virtual  de Green Belt en Talisis.com!`,
    },
    "/programa/485" : {
        "title" : `Curso de Interpretación de Psicometrías en línea: U-ERRE`,
        "description" : `Amplía tu conocimiento con el curso de educación continua de U-ERRE en Talisis.com. Descubre la extensa selección de cursos en línea que tenemos para ti.`,
    },
    "/programa/317" : {
        "title" : `Curso de Seguridad Industrial en línea: U-ERRE`,
        "description" : `Aprende las mejores prácticas en seguridad industrial con el curso de educación continua en línea de la UERRE en Talisis.com. ¡Impulsa tu carrera hoy!`,
    },
    "/programa/666" : {
        "title" : `MBA en línea - Maestría en Administración U-ERRE`,
        "description" : `Obtén tu MBA en línea de la U-ERRE en Talisis.com donde desarrollarás habilidades que te harán destacar en tu carrera. Inscríbete hoy por solo $1,800 mxn/mes.`,
    },
    "/programa/667" : {
        "title" : `Maestría en Administración de Procesos Industriales en línea`,
        "description" : `Con U-ERRE, adquiere conocimientos en administración de procesos industriales en Talisis.com. Descubre lo hay para ti en maestrias, licenciaturas y cursos.`,
    },
    "/test-vocacional/UNID" : {
        "title" : `Test Vocacional GRATIS - Descubre tu vocación con UNID`,
        "description" : `¿No estás seguro de qué carrera estudiar? Toma el test vocacional gratis de UNID en Talisis.com y descubre cuál es la carrera perfecta para ti. ¡Empieza hoy!`,
    },
    "/programa/335" : {
        "title" : `Licenciatura en Derecho en línea - UNID en Talisis.com`,
        "description" : `Estudia una licenciatura en Derecho y Ciencias Jurídicas en UNID a través de Talisis.com. ¡Descubre este y otros programas que te llevarán a tu meta!`,
    },
    "/programa/361" : {
        "title" : `Maestría en Tecnologías de la Información y Comunicación: UNID`,
        "description" : `Obtén una maestría en línea en Tecnologías de la Información y Comunicación de UNID en Talisis.com. ¡Desarrolla tus habilidades y construye tu futuro hoy mismo!`,
    },
    "/programa/518" : {
        "title" : ``,
        "description" : ``,
    },
    "/programa/404" : {
        "title" : ``,
        "description" : ``,
    },
    "/programa/596" : {
        "title" : `Desarrollo de Líderes de Alto Desempeño - curso en línea UNID`,
        "description" : `Conviértete en un líder excepcional con el curso en línea de Desarrollo de Líderes de Alto Desempeño en Talisis.com por solo $899 mxn/mes. ¡Inscríbete hoy mismo!`,
    },
    "defaultRoute" : {
        "title" : `Talisis`,
        "description" : `Estudia a tu ritmo, donde y cuando quieras.`,
    },
    "/expedientes":{
        "title" : `Expedientes`,
        "description" : ``,
    },
    "/degree-certification":{
        "title" : `Titulación y Certificación`,
        "description" : `Titulación y Certificación`,
    },
};