import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { generatePath } from "react-router";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'
import Icon from '@material-ui/core/Icon'

import { getLogoCard }from '../../../constants/logosImages';
import * as paths from '../../../routes/paths'
import { SOURCES } from '../../../shared/constants/sources';


import ArrowLeftUp from "../../../assets/images/logos/footer/arrow-left-up-line.svg";
import Mail from "../../../assets/images/logos/footer/mail-line.svg";
import Facebook from '../../../assets/images/logos/footer/facebook-line.svg';
import Instagram from '../../../assets/images/logos/footer/instagram-line.svg';
import LinkedIn from '../../../assets/images/logos/footer/linkedin-line.svg';
import Twitter from '../../../assets/images/logos/footer/twitter.svg';
import DownloadAndroidApp from '../../../assets/images/logos/footer/descargar-android-app.svg';
import DownloadAppleApp from '../../../assets/images/logos/footer/descargar-apple-app.svg';
// style
import useStyles from '../../../assets/styles/jss/layout/footerMarketStyle';
import { Button, Divider, Hidden } from '@material-ui/core';

import { v4 as uuid } from 'uuid';
import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';


const DESCARGAR_APP_URL = 'https://pointmovil.page.link/tlsNL'



function ListItemLink({text, ...rest}) {
    return  <ListItem button {...rest}>
        <ListItemIcon>
            <Icon className="ri-arrow-right-line text-orange-300 font-size-16"/>
        </ListItemIcon>
        <ListItemText primary={
            <Typography variant="body2" className="text-orange-300 semi-bold">
                {text}
            </Typography>
        }/>
    </ListItem>;
}

const brandsList = [
    {id: SOURCES.ADVENIO, logoName: 'advenio-white.svg', style:{height: 26}, href: 'https://www.advenio.mx/'},
    {id: SOURCES.HH, logoName: 'hh-white.svg', style:{height: 11}, path: generatePath(paths.ESCUELAS_OUS, {brand: 'harmonhall'})},
    {id: SOURCES.UNID, logoName: 'unid-white.svg', style:{height: 13}, path: generatePath(paths.ESCUELAS_OUS, {brand: 'unid'})},
    {id: SOURCES.UERRE, logoName: 'uerre-white.svg', style:{height: 11}, path: generatePath(paths.ESCUELAS_OUS, {brand: 'u-erre'})},
    {id: SOURCES.IESALUD, logoName: 'iesalud-white.svg', style:{height: 27}, href: 'https://iesalud.edu.mx/'},
]

const talisisList = [
    {id: 1, href: 'https://quienessomos.talisis.com/', text: 'Quiénes somos'},
    {id: 2, path: `/#aliados`, text: 'Nuestros aliados', scrollTo: true, elementId: 'carrusel-aliados'},
    {id: 3, href: 'https://talisis.sherlockhr.com', text: 'Trabaja con nosotros'},
]
const offerList = [
    {text: 'Bachillerato', path: generatePath(paths.ESCUELAS_OUS, {brand: 'unid'})},
    {text: 'Licenciaturas', path: generatePath(paths.ESCUELAS_OUS, {brand: 'unid'})},
    {text: 'Maestrías', path: generatePath(paths.ESCUELAS_OUS, {brand: 'u-erre'})},
    {text: 'Inglés profesional', path: generatePath(paths.ESCUELAS_OUS, {brand: 'harmonhall'})},
    {text: 'Educación continua', path: '/'},
]
const comList = [
    // {text: 'Conecta', path: paths.SIMPLICITY},
    {text: 'Eventos', path: paths.EVENTOS},
    {text: 'Blog', href: paths.BLOG},
]

const Footer = (props) => {
    const {isLogged, hideFooter} = props;
    const history = useHistory();
    const classes = useStyles()
    const location = useLocation();
    
    const reloadRedirect = (event, item) => {
        console.log(item)
        if (item.id == 1){
            eventTracker('info_talisis',eventData, ['mixpanel']);
        }
        if(item.scrollTo){
            scrollToSection(item.elementId);
            return;
        }
        //has external href
        if(item.hasOwnProperty('href')){    
            const target = item.target || '_blank';
            window.open(item.href, target);
            return;
        }
        //has path inside app
        event.preventDefault();
        const path = item.path || '';
        const target = item.target || '_self';
        const gotToPathname = path.split("?")[0];
        const currentPath = location.pathname;
        //open path on another tab
        if(target != '_self'){
            window.open(location.hash+path, target);
            return;
        }
        //navigate to path (with history)
        if(currentPath === gotToPathname){
            history.push(path);
            window.location.reload();
        }else{
            history.push(path)
        }
    }

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const SmallFooter = () => (
        <Grid item xs={12}>
            <Box className={classes.content_copy_footer}>
                <Box className={classes.titles_copy_footer} >
                    <Box>
                        <Link href="/terminos_condiciones">
                            <Typography className={classes.title_copy_footer}>Términos y condiciones</Typography>
                        </Link>
                    </Box>
                    <Box>
                        <Link href="/aviso_privacidad">
                            <Typography className={classes.title_copy_footer}>Aviso de Privacidad</Typography>
                        </Link>
                    </Box>
                    <Box className='copyright'>
                        <Typography className={classes.title_copy_footer}>Talisis© Copyright 2023</Typography>
                    </Box>
                </Box>
            </Box>
        </Grid>
    )

    if(isLogged){
        return  (
            <footer id="footer" className="footer-1">
                <Box className={classes.root} >
                    <SmallFooter />
                </Box>
            </footer>
        )
        
    }

    if(hideFooter) {
        return  (
            <footer id="footer" class="footer-1">
                <Box className={classes.root} >
                    <SmallFooter />
                </Box>
            </footer>
        )
    }
    const handleClickOpenAPP = () => {
        window.open(DESCARGAR_APP_URL)
    }
    
    const handleLogin = path => e => {
        if (path === paths.LOGIN) {
            eventTracker('s_login', {}, ['mixpanel']);
        }
        history.push(path);
    };

    const handleEventMixPanel = (follow) => {
        console.log('follow', follow)
        switch (follow) {
            case 'fb':
                eventTracker('follow_fb',eventData, ['mixpanel']);
                break;
            case 'inst':
                eventTracker('follow_ig',eventData, ['mixpanel']); 
                break;
            case 'in':
                eventTracker('follow_linkedin',eventData, ['mixpanel']);   
                break;
            case 'tw':
                eventTracker('follow_twitter',eventData, ['mixpanel']); 
                break;
            case 'yt':
                eventTracker('follow_yt',eventData, ['mixpanel']);
                break;
        
            default:
                break;
        }
    }

    return (
        <footer id="footer" className="footer-1">
            <Box className={classes.root} >
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <Box className={classes.logo}>
                            <img className={classes.logo_footer} alt="logo-talisis" src={getLogoCard(7)} />
                            <Typography variant="body1" className="semi-bold text-white" >Transforma tu futuro</Typography>
                        </Box>
                    </Grid>
                    
                    <Grid item container xs={12} sm={12} md={7}>
                        <Grid item xs={12} sm={4}>
                            <List component="nav" className={classes.list} aria-label="secondary mailbox folders" subheader={
                                <Box mb={1.5}>
                                    <Typography variant="overline" className="semi-bold text-white text-uppercase padding-y-4">Talisis</Typography>
                                </Box>
                            }>
                                {
                                    talisisList.map(item => 
                                        <ListItemLink 
                                            text={item.text} 
                                            onClick={(event) => reloadRedirect(event, item)} 
                                            key={uuid()} 
                                            className={classes.listItemLink}
                                        />
                                    )
                                }
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <List component="nav" className={classes.list} dense={true} aria-label="Oferta Educativa" subheader={
                                <Box mb={1.5}>
                                    <Typography variant="overline" className="semi-bold text-white text-uppercase padding-y-4">Oferta Educativa</Typography>
                                </Box>
                            }>
                                {
                                    offerList.map(item => 
                                        <ListItemLink 
                                            text={item.text} 
                                            onClick={(event) => reloadRedirect(event, item)} 
                                            key={uuid()} 
                                            className={classes.listItemLink}
                                        />
                                    )
                                }
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <List component="nav" className={classes.list} dense={true} aria-label="Comunidad" subheader={
                                <Box mb={1.5}>
                                    <Typography variant="overline" className="semi-bold text-white text-uppercase padding-y-4">Comunidad</Typography>
                                </Box>
                            }>
                                {
                                    comList.map(item => 
                                        <ListItemLink 
                                            text={item.text} 
                                            onClick={(event) => reloadRedirect(event, item)} 
                                            key={uuid()} 
                                            className={classes.listItemLink}
                                        />
                                    )
                                }
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Hidden mdDown>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end" gridGap={48} maxWidth="82%" pt="40px" pb="56px">
                                    {
                                        brandsList.map(item => 
                                            <img src={`/images/logos/${item.logoName}`}
                                                style={item.style}
                                                key={uuid()}
                                            />
                                        )
                                    }
                                </Box>
                            </Hidden>
                            <Hidden mdUp>
                                <Divider className="bg-fill-stroke-200"/>
                                <Box display="flex" alignItems="center" flexDirection="column" py="40px">
                                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end" width={1} maxWidth={275} mb={3}>
                                        {
                                            brandsList.slice(0, 3).map(item => 
                                                <img src={`/images/logos/${item.logoName}`}
                                                    style={item.style}
                                                    key={uuid()}
                                                />
                                            )
                                        }
                                    </Box>
                                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end" width={1} maxWidth={149}>
                                        {
                                            brandsList.slice(3, 5).map(item => 
                                                <img src={`/images/logos/${item.logoName}`}
                                                    style={item.style}
                                                    key={uuid()}
                                                />
                                            )
                                        }
                                    </Box>
                                </Box>
                            </Hidden>
                        </Grid>
                    </Grid>

                    {/* REDES */}
                    <Grid item xs={12} sm={12} md={2}>
                        <Hidden mdUp>
                            <Divider className="bg-fill-stroke-200"/>
                        </Hidden>
                        <Box className={classes.social}>
                            <Typography variant="overline" className="semi-bold text-white text-uppercase">Síguenos</Typography>
                            <Box className={classes.redes_info_footer} mt={3}>
                                    {/* <Link href="https://www.facebook.com/talisis/" target="_blank" onClick={ () => handleEventMixPanel('fb')}>
                                        <img className={classes.redes_footer} alt="logo-facebook" src={Facebook} />
                                    </Link> */}
                                    {/* <Link href="https://www.instagram.com/talisis/" target="_blank" onClick={ () => handleEventMixPanel('inst')}>
                                        <img className={classes.redes_footer} alt="logo-instagram" src={Instagram} />
                                    </Link> */}
                                    <Link href="https://www.linkedin.com/company/22331389/admin/" target="_blank" onClick={ () => handleEventMixPanel('in')}>
                                        <img className={classes.redes_footer} alt="logo-linkedIn" src={LinkedIn} />
                                    </Link>
                                    <Link href="https://twitter.com/talisisexp" target="_blank" onClick={ () => handleEventMixPanel('tw')}>
                                        <img className="icon-twitter" alt="logo-twitter" src={Twitter} />
                                    </Link>
                                    <Link href="https://www.youtube.com/@Talisis" target="_blank" onClick={ () => handleEventMixPanel('yt')}>
                                        <img className="icon-youtube" alt="logo-youtube" src='/images/icons/youtube.svg' />
                                    </Link>
                            </Box>
                        </Box>
                    </Grid>
                    

                    {/* Descargar APP */}
                    <Hidden mdUp>
                        <Grid item xs={12} sm={12} md={2}>
                            <Divider className="bg-fill-stroke-200"/>
                            <Box className={classes.boxDescargaAPP}>
                                <img className={classes.marginApps} src={DownloadAppleApp} alt="Descargar APP IOS Talisis" onClick={handleClickOpenAPP}/>
                                <img src={DownloadAndroidApp} alt="Descargar APP Android Talisis" onClick={handleClickOpenAPP}/>
                            </Box>
                        </Grid>
                    </Hidden>

                    <SmallFooter />
                    {
                    (location.pathname !== paths.LOGIN && location.pathname !== paths.SIGNUP) &&
                        <Hidden mdUp>
                            <Box className={classes.iniciarStyles}>
                                <Button
                                    color="primary"
                                    label="Primario"
                                    onClick={handleLogin(paths.SIGNUP)}
                                    variant="contained"
                                    style={{width:'330px', height: '48px'}}
                                >
                                    Empezar ahora
                                </Button>
                                <Button
                                    color="primary"
                                    label="Iniciar sesión"
                                    onClick={handleLogin(paths.LOGIN)}
                                    >
                                    Iniciar sesión
                                </Button>
                            </Box>
                        </Hidden>
                    }
                </Grid>
            </Box>
        </footer>
    );
};

export default Footer;