import React from 'react';
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';
import { generatePath } from "react-router";

// redux
import { useHistory } from 'react-router-dom';

//Routes
import * as paths from '../../../routes/paths'
import DsIcon from '../../../design-system/components/DsIcon/DsIcon';

// @material-ui/core
import { Grid, Typography, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CarouselAliados from '../CarouselAliados/CarouselAliados'

// shared core components
import { log } from '../../../shared/utils/console.js'
import { SOURCES } from '../../../design-system/constants/sources'
import AllyCourseCard from '../../../design-system/components/Cards/AllyCourseCard/AllyCourseCard';
import { v4 as uuid } from 'uuid';
import { CourseService } from '@sdk-point/talisis';

import { eventData, eventTracker } from '../../../shared/components/EventTracker/EventTracker';
import clsx from 'clsx';

const courseService = new CourseService(process.env.REACT_APP_ENV, process.env.REACT_APP_X_API_KEY);

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '28px 0 56px',
        '& .container': {
            display: 'flex',
            gap: 40,
            flexDirection: 'column'
        },
        '& .MuiCard-root.MuiPaper-outlined': {
            width: '100% !important',
            '& .MuiButtonBase-root .MuiCardContent-root .MuiCardContent-root': {
                height: '100% !important',
                justifyContent: 'space-between !important',
                '& .MuiBox-root:first-child': {
                    alignItems: 'flex-start'
                },
            },
        }
    }
}));

const Allys = () => {
    const classes = useStyles();
    const history = useHistory();

    // Inicializamos el estado con un arreglo vacío
    const [aliados, setAliados] = React.useState([]);

    // Función para obtener aliados desde el sdk-point
    const getData = async () => {
        try {
            const data = await courseService.getCoursesAliados();
            return data?.response || [];
        } catch (e) {
            log(e.message);
            return [];  
        }
    };

    // Cargar los datos de aliados desde la API cuando el componente se monta
    React.useEffect(() => {
        let isCanceled = false;
        getData().then((data) => {
            if (!isCanceled && data.length > 0) {
                // Si los datos se obtienen correctamente, actualizamos el estado
                console.log(data);
                setAliados(data);
            }
        }).catch((err) => {
            log(`Failed: ${err}`);
        });

        return () => {
            isCanceled = true;  // Cancela la llamada si el componente se desmonta
        };
    }, []);

    // Función para redirigir al usuario a las membresías
    const goTo = () => {
        const eventData = {};
        eventData['source'] = 'landing';
        eventTracker('ver_membresías', eventData, ['mixpanel', 'google', 'hubspot']);
        history.push(paths.MEMBRESIAS);
    };

    // Función para manejar el clic en un aliado
    const handleClick = ({ value: allyName, slug, id }) => {
        let path = '';

        if (id === SOURCES.UNID || id === SOURCES.UERRE) {
            path = generatePath(paths.ESCUELAS_OUS, { brand: slug });
        } else {
            const formattedName = allyName.toLocaleLowerCase().replace(" ", "-");
            path = generatePath(paths.ALIADOS, { name: formattedName });
        }

        eventData['source'] = 'landing';
        eventData['marcaCurso'] = slug ? (slug.split("_")[0]).replace('-', '') : '';
        eventTracker('explorar_marca', eventData, ['mixpanel']);

        history.push(path);
    };

    return (
        <>
            <Hidden smDown>
                <ResponsiveContainer className={classes.container}>
                    <Typography variant='h2'>Accede a los cursos de nuestros aliados</Typography>
                    <Grid id="carrusel-aliados" container spacing={3}>
                        {aliados.length === 0 ? (
                            <Typography variant="h6">Cargando aliados...</Typography>  // Mensaje de carga
                        ) : (
                            aliados.map(aliado => (
                                <Grid item md={4} lg={3} xs={12} key={uuid()}>
                                    <AllyCourseCard
                                        sourceId={aliado.id}
                                        sourceName={aliado.value}
                                        courses={aliado.total_cursos}
                                        onClick={() => handleClick(aliado)}
                                    />
                                </Grid>
                            ))
                        )}
                    </Grid>
                    <Box>
                        <Button color='primary' endIcon={<DsIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" icon='t-arrow-right-line' />} onClick={goTo}>Comparar membresías</Button>
                    </Box>
                </ResponsiveContainer>
            </Hidden>
            <Hidden mdUp>
                <Box>
                    <CarouselAliados
                        items={aliados}
                        className={classes.allysCarousel}
                        landing={true}
                        title='Accede a los cursos de nuestros aliados'
                        onClickCard={handleClick}
                    />
                </Box>
            </Hidden>
        </>
    );
};

export default Allys;
